import React from "react";

export default function OtherActivities({ formData, handleInputChange }) {
    return (
        <div className="Personal_section">
            <div className="cv_title_personal_text">Other Activities</div>

            <form>
                {formData.activities.map((activity, index) => (
                    <div key={activity.id} className="education_container">
                        <div className="education_div2">
                            <div className="education_title">Activity #{index + 1}</div>
                        </div>
                        <div className="underline_cv" />

                        {/* Activity Name Input */}
                        <div className="degree_div">
                            <div className="input_div_title">
                                Activity Name {!activity.name && <span className="red">*</span>}
                                <div className="profile_description">
                                    Scouts, camp, volunteering, fundraising
                                </div>
                            </div>

                            <div className="inputsss_cont">
                                <input
                                    type="text"
                                    className="input_normal_builder"
                                    placeholder="Activity Name"
                                    value={activity.name}
                                    onChange={(event) =>
                                        handleInputChange("activities", index, "name", event.target.value)
                                    }
                                    onKeyDown={(event) => {
                                        if (event.key === "Enter") {
                                            event.preventDefault();
                                        }
                                    }}
                                />
                            </div>
                        </div>

                        {/* Add / Remove Buttons */}
                        <div className="button_contss">
                            {formData.activities.length < 4 && index === formData.activities.length - 1 && (
                                <div className="education_div_add_2">
                                    <div
                                        onClick={() =>
                                            handleInputChange("activities", formData.activities.length, "", {
                                                id: Date.now(),
                                                name: "",
                                            })
                                        }
                                        className="education_title_2"
                                    >
                                        Add Activity
                                    </div>
                                </div>
                            )}
                            {index > 0 && (
                                <button
                                    type="button"
                                    onClick={() => handleInputChange("activities", index, "remove", null)}
                                    className="education_title_3_but"
                                >
                                    X
                                </button>
                            )}
                        </div>
                    </div>
                ))}
            </form>
        </div>
    );
}
