import React from "react";

export default function AcademicAchievements({formData, errors, handleInputChange, setFormData}) {
    const handleChange = (index, field, value) => {
        handleInputChange("education", index, field, value);
    };

    const handleAddSection = () => {
        setFormData((prevData) => ({
            ...prevData,
            education: [...prevData.education, {
                school: "",
                degree: "",
                startDate: "",
                endDate: "",
                present: false,
                specialization: "",
                location: ""
            }]
        }));
    };

    const handleRemoveSection = (index) => {
        setFormData((prevData) => ({
            ...prevData,
            education: prevData.education.filter((_, i) => i !== index),
        }));
    };

    return (
        <div className="Personal_section">
            <div className="cv_title_personal_text">Academic Achievements</div>
            <form>
                {formData.education.map((educationItem, index) => (
                    <div key={index} className="education_container">
                        <div className="education_div">
                            <div className="education_title">Degree #{index + 1}</div>
                        </div>
                        <div className="underline_cv"/>

                        {/* School Input */}
                        <div className="inputs_div margin">
                            <div className="input_div_title">
                                School / University / Institute {!educationItem.school &&
                                <span className="red">*</span>}
                            </div>
                            <input
                                type="text"
                                className="input_normal_builder"
                                placeholder="Enter Your School"
                                value={educationItem.school}
                                onChange={(e) => handleChange(index, "school", e.target.value)}
                                required
                            />
                            {errors?.education?.[index]?.school &&
                                <p className="red">{errors.education[index].school}</p>}
                        </div>
                        <div className="underline_cv margin"/>

                        {/* Degree Input */}
                        <div className="inputs_div margin">
                            <div className="input_div_title">
                                Degree {!educationItem.degree && <span className="red">*</span>}
                            </div>
                            <input
                                type="text"
                                className="input_normal_builder"
                                placeholder="Enter Your Degree"
                                value={educationItem.degree}
                                onChange={(e) => handleChange(index, "degree", e.target.value)}
                                required
                            />
                            {errors?.education?.[index]?.degree &&
                                <p className="red">{errors.education[index].degree}</p>}
                        </div>
                        <div className="underline_cv margin"/>

                        {/* Specialization Input */}
                        <div className="inputs_div margin">
                            <div className="input_div_title">
                                Specialization {!educationItem.specialization && <span className="red">*</span>}
                            </div>
                            <input
                                type="text"
                                className="input_normal_builder"
                                placeholder="Enter Your Specialization"
                                value={educationItem.specialization}
                                onChange={(e) => handleChange(index, "specialization", e.target.value)}
                                required
                            />
                            {errors?.education?.[index]?.specialization &&
                                <p className="red">{errors.education[index].specialization}</p>}
                        </div>
                        <div className="underline_cv margin"/>

                        {/* Period (Start Date - End Date) */}
                        <div className="dates_loc">
                            <div className="dates_div">
                                <div className="input_div_title">
                                    Period {!educationItem.startDate || (!educationItem.endDate && !educationItem.present) ?
                                    <span className="red">*</span> : ""}
                                </div>
                                <div className="datesss_cont">
                                    <label className="dates_in">
                                        From:
                                        <input
                                            type="date"
                                            className="input_date_builder"
                                            value={educationItem.startDate}
                                            onChange={(e) => handleChange(index, "startDate", e.target.value)}
                                            max={educationItem.endDate}
                                            required
                                        />
                                    </label>
                                    {!educationItem.present && (
                                        <label className="dates_in">
                                            To:
                                            <input
                                                type="date"
                                                className="input_date_builder"
                                                value={educationItem.endDate || ""}
                                                onChange={(e) => handleChange(index, "endDate", e.target.value)}
                                                min={educationItem.startDate}
                                                required={!educationItem.present}
                                            />
                                        </label>
                                    )}
                                    <label className="dates_in">
                                        Present
                                        <input
                                            type="checkbox"
                                            className="input_small_builder_2"
                                            checked={educationItem.present}
                                            onChange={(e) => handleChange(index, "present", e.target.checked)}
                                        />
                                    </label>
                                </div>
                            </div>

                            {/* Location Input */}
                            <div className="loc_div">
                                <div className="input_div_title">
                                    Location {!educationItem.location && <span className="red">*</span>}
                                </div>
                                <input
                                    type="text"
                                    className="input_normal_builder"
                                    placeholder="Enter Location"
                                    value={educationItem.location}
                                    onChange={(e) => handleChange(index, "location", e.target.value)}
                                    required
                                />
                                {errors?.education?.[index]?.location &&
                                    <p className="red">{errors.education[index].location}</p>}
                            </div>
                        </div>

                        {/* Add / Remove Buttons */}
                        <div className="button_contss">
                            {formData.education.length < 3 && index === formData.education.length - 1 && (
                                <div className="education_div_add">
                                    <div onClick={handleAddSection} className="education_title_2">
                                        Add Degree
                                    </div>
                                </div>
                            )}
                            {index > 0 && (
                                <div className="education_div_remove">
                                    <div onClick={() => handleRemoveSection(index)} className="education_title_3">
                                        X
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                ))}
            </form>
        </div>
    );
}
