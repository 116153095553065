import API_ROUTES from "./routes";
import mehnatiAxios from "../lib/axios";

const getDistrictsList = async () => {
  const res = await mehnatiAxios.get(API_ROUTES.GET.DISTRICTS);
  return res.data?.data ?? {};
};

const getExpertiseList = async () => {
  const res = await mehnatiAxios.get(API_ROUTES.GET.EXPERTISE);
  return res.data?.data ?? {};
};

const getExperienceList = async () => {
  const res = await mehnatiAxios.get(API_ROUTES.GET.EXPERIENCE);
  return res.data?.data ?? {};
};

const getDegreeList = async () => {
  const res = await mehnatiAxios.get(API_ROUTES.GET.DEGREE);
  return res.data?.data ?? {};
};

const getQuestionAnswers = async () => {
  const res = await mehnatiAxios.get(API_ROUTES.GET.QA);
  return res.data?.data ?? {};
};

const getAboutUS = async () => {
  const res = await mehnatiAxios.get(API_ROUTES.GET.ABOUT_US);
  return res.data?.data ?? {};
};

const getActivities = async () => {
  const res = await mehnatiAxios.get(API_ROUTES.GET.ACTIVITY);
  return res.data?.data ?? {};
};

const getCategories = async () => {
  const res = await mehnatiAxios.get(API_ROUTES.GET.CATEGORY);
  return res.data?.data ?? {};
};

const getCompanies = async ({ activityIds = [], search = "" } = {}) => {
  const queryParams = new URLSearchParams();

  if (activityIds.length > 0) {
    queryParams.append("activity_id", JSON.stringify(activityIds));
  }

  if (search) {
    queryParams.append("search", search);
  }

  const url = `${API_ROUTES.GET.COMPANY}?${queryParams.toString()}`;
  const res = await mehnatiAxios.get(url);
  return res.data?.data ?? {};
};

const getCompanyById = async ({ companyId = "" }) => {
  const res = await mehnatiAxios.get(API_ROUTES.GET.COMPANY_BY_ID(companyId));
  return res.data ?? {};
};

const getSavedJobs = async () => {
  const res = await mehnatiAxios.get(API_ROUTES.GET.SAVED_JOBS);
  return res.data?.data ?? {};
};

const saveJob = async (data = {}) => {
  const res = await mehnatiAxios.post(API_ROUTES.POST.SAVE_JOB, data);
  return res.data;
};

const unsaveJob = async (data = {}) => {
  const res = await mehnatiAxios.delete(
    API_ROUTES.DELETE.DELETE_SAVED_JOB(data.id),
  );
  return res.data;
};

const getSuccessStories = async () => {
  const res = await mehnatiAxios.get(API_ROUTES.GET.SUCCESS_STORIES);
  return res.data?.data ?? {};
};

const getCompanyStatistics = async () => {
  const res = await mehnatiAxios.get(API_ROUTES.GET.COMPANY_STATISTICS);
  return res.data?.data ?? {};
};

const getHomepageCompanies = async () => {
  const res = await mehnatiAxios.get(API_ROUTES.GET.HOMEPAGE_COMPANIES);
  return res.data?.data ?? {};
};

const getJobs = async ({
  districtId = [],
  expertiseId = [],
  jobType = [],
  educationLevelId = [],
  experienceLevelId = [],
  search = "",
} = {}) => {
  const queryParams = new URLSearchParams();

  if (districtId.length > 0) {
    if (districtId.length == 1) {
      queryParams.append("district_id", districtId[0]);
    } else {
      queryParams.append("district_id", JSON.stringify(districtId));
    }
  }

  if (expertiseId.length > 0) {
    if (expertiseId.length == 1) {
      queryParams.append("category_id", expertiseId[0]);
    } else {
      queryParams.append("category_id", JSON.stringify(expertiseId));
    }
  }

  if (jobType.length > 0) {
    if (jobType.length == 1) {
      queryParams.append("job_type", jobType[0]);
    } else {
      queryParams.append("job_type", JSON.stringify(jobType));
    }
  }

  if (educationLevelId.length > 0) {
    if (educationLevelId.length == 1) {
      queryParams.append("education_level_id", educationLevelId[0]);
    } else {
      queryParams.append(
        "education_level_id",
        JSON.stringify(educationLevelId),
      );
    }
  }

  if (experienceLevelId.length > 0) {
    if (experienceLevelId.length == 1) {
      queryParams.append("experience_level_id", experienceLevelId[0]);
    } else {
      queryParams.append(
        "experience_level_id",
        JSON.stringify(experienceLevelId),
      );
    }
  }

  if (search) {
    queryParams.append("search", search);
  }

  const res = await mehnatiAxios.get(
    `${API_ROUTES.GET.GET_JOBS}?${queryParams.toString()}`,
  );
  return res.data?.data ?? {};
};

const getJobById = async ({ id = "" } = {}) => {
  const res = await mehnatiAxios.get(API_ROUTES.GET.GET_JOB_BY_ID(id));
  return res.data ?? {};
};

const checkIfJobApplied = async ({ id = "" } = {}) => {
  const res = await mehnatiAxios.get(API_ROUTES.GET.GET_IS_JOB_APPLIED(id));
  return res.data?.data ?? false;
};

const applyForJob = async ({ id = "" } = {}) => {
  const res = await mehnatiAxios.post(API_ROUTES.POST.APPLY_FOR_JOB, {
    job_id: id,
  });
  return res.data?.data ?? {};
};

const createCV = async (data = {}) => {
  const res = await mehnatiAxios.post(API_ROUTES.POST.CREATE_CV, data);
  return res.data ?? {};
};

const getAppliedJobs = async () => {
  const res = await mehnatiAxios.get(API_ROUTES.GET.GET_APPLIED_JOBS);
  return res.data?.data ?? {};
};

const getMyInfo = async () => {
  const res = await mehnatiAxios.get(API_ROUTES.GET.GET_USER_INFO);
  return res.data?.data ?? {};
};

const editMyInfo = async (data = {}) => {
  const res = await mehnatiAxios.put(API_ROUTES.PUT.EDIT_MY_INFO, data);
  return res.data?.data ?? {};
};

const editMyImg = async (data = {}) => {
  const res = await mehnatiAxios.put(API_ROUTES.PUT.EDIT_MY_IMAGE, data);
  return res.data?.data ?? {};
};

const contactUs = async ({
  fullName = "",
  email = "",
  number = "",
  message = "",
} = {}) => {
  const res = await mehnatiAxios.post(API_ROUTES.POST.CONTACT_US, {
    full_name: fullName,
    email: email,
    phone_number: number,
    message: message,
  });
  return res.data?.data ?? {};
};


const registerUser = async (data={})=>{
  const res = await mehnatiAxios.post(API_ROUTES.POST.REGISTER, data)
  return res?.data
}

const mehnatiApi = {
  getMyInfo,
  editMyImg,
  editMyInfo,
  getDistrictsList,
  getExpertiseList,
  getDegreeList,
  getExperienceList,
  getQuestionAnswers,
  getAboutUS,
  getActivities,
  getCategories,
  getCompanies,
  getCompanyById,
  getSavedJobs,
  saveJob,
  unsaveJob,
  getSuccessStories,
  getCompanyStatistics,
  getHomepageCompanies,
  applyForJob,
  getJobById,
  checkIfJobApplied,
  getJobs,
  createCV,
  getAppliedJobs,
  contactUs,
  registerUser
};

export default mehnatiApi;
