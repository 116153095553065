import React from "react";

export default function Skills({ formData, handleInputChange }) {
    return (
        <div className="Personal_section">
            <div className="cv_title_personal_text">Skills</div>

            <form>
                {formData.skills.map((skill, index) => (
                    <div key={skill.id} className="education_container">
                        <div className="education_div2">
                            <div className="education_title">Skill #{index + 1}</div>
                        </div>
                        <div className="underline_cv" />

                        {/* Skill Name Input */}
                        <div className="degree_div">
                            <div className="input_div_title">
                                Skill Name {!skill.name && <span className="red">*</span>}
                            </div>
                            <div className="inputsss_cont">
                                <input
                                    type="text"
                                    className="input_normal_builder"
                                    placeholder="Skill Name"
                                    value={skill.name}
                                    onChange={(event) =>
                                        handleInputChange("skills", index, "name", event.target.value)
                                    }
                                    onKeyDown={(event) => {
                                        if (event.key === "Enter") {
                                            event.preventDefault();
                                        }
                                    }}
                                />
                            </div>
                        </div>

                        {/* Add / Remove Buttons */}
                        <div className="button_contss">
                            {formData.skills.length < 10 && index === formData.skills.length - 1 && (
                                <div className="education_div_add_2">
                                    <div
                                        onClick={() =>
                                            handleInputChange("skills", formData.skills.length, "", {
                                                id: Date.now(),
                                                name: "",
                                            })
                                        }
                                        className="education_title_2"
                                    >
                                        Add Skill
                                    </div>
                                </div>
                            )}
                            {index > 0 && (
                                <button
                                    type="button"
                                    onClick={() => handleInputChange("skills", index, "remove", null)}
                                    className="education_title_3_but"
                                >
                                    X
                                </button>
                            )}
                        </div>
                    </div>
                ))}
            </form>
        </div>
    );
}
