import React from "react";

export default function ProfessionalExperience({ formData, handleInputChange }) {
    return (
        <div className="Personal_section">
            <div className="cv_title_personal_text">Professional Experience</div>

            <form>
                {formData.experiences.map((experienceItem, index) => (
                    <div key={index} className="education_container">
                        <div className="education_div2">
                            <div className="education_title">Experience #{index + 1}</div>
                        </div>
                        <div className="underline_cv" />

                        {/* Company Name */}
                        <div className="degree_div">
                            <div className="input_div_title">Company Name</div>
                            <div className="inputsss_cont">
                                <input
                                    type="text"
                                    placeholder="Enter Your Company"
                                    className="input_normal_builder"
                                    value={experienceItem.company}
                                    onChange={(event) =>
                                        handleInputChange("experiences", index, "company", event.target.value)
                                    }
                                    required
                                />
                            </div>
                        </div>

                        <div className="underline_cv margin" />

                        {/* Position */}
                        <div className="degree_div">
                            <div className="input_div_title">Position</div>
                            <div className="inputsss_cont">
                                <input
                                    type="text"
                                    className="input_normal_builder"
                                    placeholder="Enter your Position"
                                    value={experienceItem.title}
                                    required
                                    onChange={(event) =>
                                        handleInputChange("experiences", index, "title", event.target.value)
                                    }
                                />
                            </div>
                        </div>

                        <div className="underline_cv margin" />

                        {/* Work Period */}
                        <div className="dates_loc">
                            <div className="dates_div">
                                <div className="input_div_title">Period</div>
                                <div className="datesss_cont">
                                    <label className="dates_in">
                                        From:
                                        <input
                                            required
                                            type="date"
                                            className="input_date_builder"
                                            value={experienceItem.startDate}
                                            onChange={(event) =>
                                                handleInputChange("experiences", index, "startDate", event.target.value)
                                            }
                                            max={experienceItem.endDate}
                                        />
                                    </label>

                                    {/* End Date (Hidden if "Present" is checked) */}
                                    {!experienceItem.present && (
                                        <label className="dates_in">
                                            To:
                                            <input
                                                type="date"
                                                className="input_date_builder"
                                                value={experienceItem.endDate || ""}
                                                onChange={(event) =>
                                                    handleInputChange("experiences", index, "endDate", event.target.value)
                                                }
                                                min={experienceItem.startDate}
                                                required={!experienceItem.present}
                                            />
                                        </label>
                                    )}

                                    {/* Present Checkbox */}
                                    <label className="dates_in">
                                        Present
                                        <input
                                            type="checkbox"
                                            className="input_small_builder_2"
                                            checked={experienceItem.present}
                                            onChange={(event) =>
                                                handleInputChange("experiences", index, "present", event.target.checked)
                                            }
                                        />
                                    </label>
                                </div>
                            </div>

                            {/* Location */}
                            <div className="loc_div">
                                <div className="input_div_title">Location</div>
                                <div className="inputsss_cont">
                                    <input
                                        type="text"
                                        placeholder="Location"
                                        className="input_normal_builder"
                                        value={experienceItem.location}
                                        onChange={(event) =>
                                            handleInputChange("experiences", index, "location", event.target.value)
                                        }
                                        required
                                    />
                                </div>
                            </div>
                        </div>

                        {/* Main Duties */}
                        <div className="profile_div">
                            <div className="input_div_title">Main Duties</div>
                            <textarea
                                className="profile_textarea"
                                placeholder="Main Duties"
                                value={experienceItem.description}
                                onChange={(event) =>
                                    handleInputChange("experiences", index, "description", event.target.value)
                                }
                                required
                            />
                            <div className="warning-message">
                                Please enter each duty on a new line starting with a '-'
                            </div>
                        </div>

                        {/* Add / Remove Buttons */}
                        <div className="button_contss">
                            {formData.experiences.length < 4 && index === formData.experiences.length - 1 && (
                                <div className="education_div_add_2">
                                    <div
                                        onClick={() =>
                                            handleInputChange("experiences", formData.experiences.length, "", {
                                                title: "",
                                                company: "",
                                                startDate: "",
                                                endDate: "",
                                                present: false,
                                                description: "",
                                                location: "",
                                            })
                                        }
                                        className="education_title_2"
                                    >
                                        Add Experience
                                    </div>
                                </div>
                            )}
                            {index > 0 && (
                                <div className="education_div_remove">
                                    <div
                                        onClick={() =>
                                            handleInputChange("experiences", index, "remove", null)
                                        }
                                        className="education_title_3"
                                    >
                                        X
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                ))}
            </form>
        </div>
    );
}
