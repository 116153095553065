import React from "react";

export default function Language({ formData, handleInputChange }) {
    return (
        <div className="Personal_section">
            <div className="cv_title_personal_text">Languages</div>

            <form>
                {formData.languages.map((language, index) => (
                    <div key={language.id} className="education_container">
                        <div className="education_div2">
                            <div className="education_title">Language #{index + 1}</div>
                        </div>
                        <div className="underline_cv" />

                        {/* Language Name Input */}
                        <div className="degree_div">
                            <div className="input_div_title">
                                Language {!language.name && <span className="red">*</span>}
                            </div>
                            <div className="inputsss_cont">
                                <input
                                    type="text"
                                    className="input_normal_builder"
                                    placeholder="Language"
                                    value={language.name}
                                    onChange={(event) =>
                                        handleInputChange("languages", index, "name", event.target.value)
                                    }
                                />
                            </div>
                        </div>

                        <div className="underline_cv margin" />

                        {/* Proficiency Level Selection */}
                        <div className="degree_div">
                            <div className="input_div_title">
                                Level {!language.proficiency && <span className="red">*</span>}
                            </div>
                            <div className="levelss_cont">
                                {["Beginner", "Intermediate", "Advanced", "Mother Language"].map((level) => (
                                    <div
                                        key={level}
                                        className="level_div"
                                        onClick={() =>
                                            handleInputChange("languages", index, "proficiency", level)
                                        }
                                    >
                                        <div
                                            className={
                                                language.proficiency === level
                                                    ? "inputdesignactive"
                                                    : "inputdesign"
                                            }
                                        />
                                        <div className="title_level">{level}</div>
                                    </div>
                                ))}
                            </div>
                        </div>

                        {/* Add / Remove Buttons */}
                        <div className="button_contss">
                            {formData.languages.length < 4 && index === formData.languages.length - 1 && (
                                <div className="education_div_add_2">
                                    <div
                                        onClick={() =>
                                            handleInputChange("languages", formData.languages.length, "", {
                                                id: Date.now(),
                                                name: "",
                                                proficiency: "",
                                            })
                                        }
                                        className="education_title_2"
                                    >
                                        Add Language
                                    </div>
                                </div>
                            )}
                            {index > 0 && (
                                <button
                                    type="button"
                                    onClick={() => handleInputChange("languages", index, "remove", null)}
                                    className="education_title_3_but"
                                >
                                    X
                                </button>
                            )}
                        </div>
                    </div>
                ))}
            </form>
        </div>
    );
}
