import React from "react";

export default function PersonalInformation({formData, errors, setFormData}) {
    const handleChange = (field, value) => {
        setFormData((prevData) => ({
            ...prevData,
            [field]: value,
        }));
    };

    return (
        <div className="Personal_section">
            <div className="cv_title_personal_text">Personal Information</div>
            <form>
                <div className="input_container">
                    <div className="divss_container">
                        <div className="right_div">
                            <div className="underline_cv"/>
                            <div className="inputs_div margin">
                                <div className="input_div_title">
                                    First Name {!formData.firstName && <span className="red">*</span>}
                                </div>
                                <input
                                    type="text"
                                    className="input_normal_builder"
                                    placeholder="First Name"
                                    value={formData.firstName}
                                    onChange={(e) => handleChange("firstName", e.target.value)}
                                    required
                                />
                                {errors.firstName && <p className="red">{errors.firstName}</p>}
                            </div>
                            <div className="underline_cv margin"/>
                            <div className="inputs_div margin">
                                <div className="input_div_title">
                                    Last Name {!formData.lastName && <span className="red">*</span>}
                                </div>
                                <input
                                    type="text"
                                    className="input_normal_builder"
                                    placeholder="Last Name"
                                    value={formData.lastName}
                                    onChange={(e) => handleChange("lastName", e.target.value)}
                                    required
                                />
                                {errors.lastName && <p className="red">{errors.lastName}</p>}
                            </div>
                            <div className="underline_cv margin"/>
                            <div className="inputs_div margin">
                                <div className="input_div_title">
                                    Phone Number {!formData.phone && <span className="red">*</span>}
                                </div>
                                <input
                                    type="number"
                                    className="input_normal_builder no-arrows"
                                    placeholder="Phone Number"
                                    value={formData.phone}
                                    onChange={(e) => handleChange("phone", e.target.value)}
                                    required
                                />
                                {errors.phone && <p className="red">{errors.phone}</p>}
                            </div>
                        </div>
                        <div className="left_div">
                            <div className="underline_cv"/>
                            <div className="inputs_div margin">
                                <div className="input_div_title">
                                    Address {!formData.address && <span className="red">*</span>}
                                </div>
                                <input
                                    type="text"
                                    className="input_normal_builder"
                                    placeholder="Address"
                                    value={formData.address}
                                    onChange={(e) => handleChange("address", e.target.value)}
                                    required
                                />
                                {errors.address && <p className="red">{errors.address}</p>}
                            </div>
                            <div className="underline_cv margin"/>
                            <div className="inputs_div margin">
                                <div className="input_div_title">
                                    Email Address {!formData.email && <span className="red">*</span>}
                                    <div className="profile_description">Use a professional e-mail</div>
                                </div>
                                <input
                                    type="text"
                                    className="input_normal_builder"
                                    placeholder="Email Address"
                                    value={formData.email}
                                    onChange={(e) => handleChange("email", e.target.value)}
                                    required
                                />
                                {errors.email && <p className="red">{errors.email}</p>}
                            </div>
                            <div className="underline_cv margin"/>
                            <div className="inputs_div margin">
                                <div className="input_div_title">
                                    School / University / Institute {!formData.school && <span className="red">*</span>}
                                </div>
                                <input
                                    type="text"
                                    className="input_normal_builder"
                                    placeholder="Enter your School Name"
                                    value={formData.school}
                                    onChange={(e) => handleChange("school", e.target.value)}
                                    required
                                />
                                {errors.school && <p className="red">{errors.school}</p>}
                            </div>
                        </div>
                    </div>
                    <div className="underline_cv"/>
                    <div className="profile_div">
                        <div className="input_div_title">
                            Profile <span className="red">*</span>
                        </div>
                        <div className="profile_description">
                            Write two sentences to present yourself and skills to the employer, mentioning the job
                            position you're applying for.
                        </div>
                        <textarea
                            className="profile_textarea"
                            placeholder="Profile"
                            value={formData.profile}
                            onChange={(e) => handleChange("profile", e.target.value)}
                            required
                        />
                        {errors.profile && <p className="red">{errors.profile}</p>}
                    </div>
                </div>
            </form>
        </div>
    );
}
